<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('Storage.nav.SKU_information') }}
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_daaaeb1b7b22b126')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">

				</el-col>
			</el-row>
		</div>

		<div class="">
			<el-result icon="success" :title="$t('i18nn_bc868e024b80d2e3')" sub:title="$t('i18nn_77c91da599d5b4b6')">
				<template slot="extra">
					<el-button type="primary" size="medium" icon="el-icon-back" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>
					<!-- <el-button type="primary" plain size="medium" @click="toPage()">{{$t('i18nn_b0493e82a0426f5c')}}</el-button> -->
					
					<div style="margin-top: 10px;">
						<strong style="font-size: 16px;"> {{Timer.num}} </strong>{{$t('i18nn_a946a88e4f41f9c6')}}</div>
					
				</template>
			</el-result>
		</div>

	</div>
</template>
<script>
	export default {

		components: {

		},
		props: {

		},
		data() {
			return {
				Timer: {
				  disabled: false,
				  num: null,
				  timer: null,
				},
			};
		},
		//创建时
		created() {
			this.autoTimerAction();
		},
		//编译挂载前
		mounted() {

		},
		//离开之前
		// beforeRouteLeave(to, from, next) {
		// 	clearTimeout(this.Timer.timer);
		// },
		methods: {
			goBack(){
				clearTimeout(this.Timer.timer);
				this.$router.go(-1);
			},
			toPage(){
				clearTimeout(this.Timer.timer);
				this.$router.push({name:"createOrder",query:{orderType:'60'}})
			},
			//倒计时
			autoTimerAction(){
				this.Timer.num = 5;
				this.autoTimer();
			},
			//倒计时
			autoTimer(){
				console.log('autoTimer');
				clearTimeout(this.Timer.timer);
				this.Timer.timer = setTimeout(()=> {
				  if (this.Timer.num > 0 && this.Timer.num <= 5) {
				    this.Timer.num--;
						this.autoTimer();
				  } else {
				    clearTimeout(this.Timer.timer);
						this.goBack();
				  }
				}, 1000);
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
